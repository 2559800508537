<template>
  <div class="recap" :class="{stepActive: active}">
    <div class="recap-header">
      <div class="label mb-20">{{  $t('paCalculator') }}</div>
    </div>
    <div class="recap-subheader">
      <svg viewBox="0 0 500 100" preserveAspectRatio="xMinYMin meet">
        <defs>
          <linearGradient id="myGradient" gradientTransform="rotate(0)">
            <stop offset="0%" class="gradient1" />
            <stop offset="50%" class="gradient2" />
            <stop offset="100%" class="gradient3" />
          </linearGradient>
        </defs>
        <path d="M0,0 C200,100 350,0 500,50 L500,0 L0,0 Z" style="stroke: none;"></path>
      </svg>
    </div>
    <div class="recap-content">

      <template v-if="isEnded && !requesting">
        <div class="info-text success" v-if="!errored">
          <p>
            {{ $t('infoEmailSuccess')}}
          </p>
        </div>
        <div class="info-text errored" v-else>
          <p>
            {{ $t('infoEmailErrored')}}
          </p>
        </div>
      </template>

      <div class="value-container" v-if="hasResult">
        <pa-result-simple :label="$t('type')" :value="typeValue"/>
        <pa-result-simple :label="$t('paValueToCompute')" :value="processValue"/>
        <pa-result-simple :label= "$t('paDensity')" :value="numberize(density, 1)" unit="Kg/m<sup>3</sup>"/>
        <pa-result-simple :label="$t('diameter')" v-if="isCircular" :value="numberize(circularDiameter, 1)" unit="mm"/>
        <pa-result-simple :label="$t('flowRate')" :value="numberize(flow, 1)" unit="m<sup>3</sup>/h"/>
        <pa-result-simple :label="$t('paSpeed')" :value="numberize(speed, 1)" unit="m/s"/>
        <pa-result-simple :label="$t('paLinearPressureLoss')" :value="numberize(linearPressureLoss, 1)" unit="Pa/m"/>
        <pa-container :label="$t('paAccidentalPressureLoss')">
          <template v-if="isCircular">
            <pa-result-simple :label="$t('paElbow30') +'Ø'" :value="numberize(coude30, 1)" unit="Pa"/>
            <pa-result-simple :label="$t('paElbow45') +'Ø'" :value="numberize(coude45, 1)" unit="Pa"/>
            <pa-result-simple :label="$t('paElbow90') +'Ø'" :value="numberize(coude90, 1)" unit="Pa"/>
          </template>
          <template v-if="isRectangular">
            <pa-result-simple :label="$t('paElbow30') +'Ø'" :value="numberize(coude30, 1)" unit="Pa"/>
            <pa-result-simple :label="$t('paElbow45') +'Ø'" :value="numberize(coude45, 1)" unit="Pa"/>
            <pa-result-simple :label="$t('paElbow90') +'Ø'" :value="numberize(coude90, 1)" unit="Pa"/>
          </template>
          <pa-result-simple :label="$t('paInvertedLateralTee90')" :value="numberize(lateral90, 1)" unit="Pa"/>
          <pa-result-simple :label="$t('paRootTee90')"  :value="numberize(souche, 1)" unit="Pa"/>
          <pa-result-simple :label="$t('paNetworkOutput')"  :value="numberize(output, 1)" unit="Pa"/>
          <div class="spacer pa-result-line"></div>
          <template v-if="isCircular">
            <pa-result-simple :label="'Ø' + $t('upstream')" :value="numberize(circularDiameter, 1)" unit="mm"/>
            <pa-result-simple :label="'Ø' + $t('downstream')" :value="numberize(circularDownstreamDiameter, 1)" unit="mm"/>
          </template>
          <template v-if="isRectangular">
            <pa-result-simple :label="$t('paDownstreamHeight') " :value="numberize(rectangularDownstreamHeight)" unit="mm"/>
            <pa-result-simple :label="$t('paDownstreamWidth') " :value="numberize(rectangularDownstreamWidth)" unit="mm"/>
          </template>
          <pa-result-simple :label="$t('paAbruptExpansion')" :value="numberize(abruptExpansion, 1)" unit="Pa"/>
          <pa-result-simple :label="$t('paConicalExpansion')" :value="numberize(conicExpansion, 1)" unit="Pa"/>
          <pa-result-simple :label="$t('paAbruptReduction')" :value="numberize(abruptReduction, 1)" unit="Pa"/>
          <pa-result-simple :label="$t('paConicalReduction')" :value="numberize(conicReduction, 1)" unit="Pa"/>
          <div class="spacer pa-result-line"></div>
          <pa-result-simple :label="$t('paDownstreamFlowRate')" :value="numberize(downstreamFlow, 1)"/>
          <pa-result-simple :label="$t('lateralFlowStream')" :value="numberize(lateralFlow, 1)"/>
          <pa-result-simple :label="$t('paStraightTee90')" :value="numberize(straightTee90, 1)" unit="Pa"/>
          <pa-result-simple :label="$t('paLateralTee90')" :value="numberize(lateralTee90, 1)" unit="Pa"/>
        </pa-container>
      </div>
      <hr>
      <div class="input-group" v-if="!isEnded && !requesting">
        <span>{{ $t('receiveResultsEmail') }}</span>
        <input type="email" id="email" class="form-control" :class="{error: isEmailErrored}" :placeholder="$t('emailAddress')"
               v-model="email"/>
      </div>
      <div class="input-group input-cbx" v-if="!isEnded">
        <label for="cbx">{{ $t('agreeToReceiveFurtherInformationsEmail') }}</label>
        <input type="checkbox" name="cbx" id="cbx" class="cbx" v-model="accept">
      </div>
    </div>
    <div class="recap-footer">
      <back-btn @click="clickBack">{{ $t('goBack') }}</back-btn>
      <ok-btn @click="clickOk" :disabled="isEmailErrored || requesting" v-if="!isEnded">{{ $t('send')}}</ok-btn>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import PaResultSimple from "./parts/pa-result-simple";
import {PA_TYPES, PROCESS_TYPE} from "../store/pa.store";
import numberize from '../_helper/numberize.helper';
import PaContainer from "./parts/pa-container";
import BackBtn from "./parts/back-btn";
import OkBtn from "./parts/ok-btn";

export default {
  name: "pa-result",
  components: {OkBtn, BackBtn, PaContainer, PaResultSimple},
  data () {
    return {
      email: '',
      accept: false
    }
  },
  methods: {
    numberize,
    clickBack () {
      this.$store.commit('pa/SET_STEP', 0);
    },
    async clickOk () {
      console.log('clickOk');
      return this.$store.dispatch('pa/send', {email: this.email, accept: this.accept});
    },
  },
  computed: {
    ...mapState({
      active: s => s.pa.step > 0,
      density: s => s.pa.density,
      circularDiameter: s => s.pa.circular.diameter,
      circularDownstreamDiameter: s => s.pa.circular.downstreamDiameter,
      rectangularDownstreamHeight: s => s.pa.rectangular.downstreamHeight,
      rectangularDownstreamWidth: s => s.pa.rectangular.downstreamWidth,
      downstreamFlow: s => s.pa.downstreamFlow,
      lateralFlow: s => s.pa.lateralFlow,
      hasResult: s => s.pa.result !== null,
      done: s => s.pa.step > 2,
      isEnded: s => s.pa.step === 2,
      requesting: s => s.pa.requesting,
      errored: s => s.pa.hasError
    }),
    isEmailErrored () {
      return this.email.indexOf('@') === -1 || !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email);
    },
    flow() {
      return this.$store.getters["pa/flow"]
    },
    speed() {
      return this.$store.getters["pa/speed"]
    },
    linearPressureLoss() {
      return this.$store.getters["pa/linearPressureLoss"];
    },
    coude30() {
      return this.$store.getters["pa/coude30"]
    },
    coude45() {
      return this.$store.getters["pa/coude45"]
    },
    coude90() {
      return this.$store.getters["pa/coude90"]
    },
    lateral90() {
      return this.$store.getters["pa/lateral90"]
    },
    souche() {
      return this.$store.getters["pa/souche"]
    },
    output() {
      return this.$store.getters["pa/output"]
    },
    abruptExpansion() {
      return this.$store.getters["pa/abruptExpansion"]
    },
    conicExpansion() {
      return this.$store.getters["pa/conicExpansion"]
    },
    abruptReduction() {
      return this.$store.getters["pa/abruptReduction"]
    },
    conicReduction() {
      return this.$store.getters["pa/conicReduction"]
    },
    straightTee90() {
      return this.$store.getters["pa/straightTee90"]
    },
    lateralTee90() {
      return this.$store.getters["pa/lateralTee90"]
    },
    isCircular() {
      return this.$store.state.pa.type === PA_TYPES.CIRCULAR;
    },
    isRectangular() {
      return this.$store.state.pa.type === PA_TYPES.RECTANGULAR;
    },
    typeValue() {
      return this.isCircular ? this.$t('paCircular') : this.$t('paRectangular');
    },
    processValue() {
      return this.$store.state.pa.processType === PROCESS_TYPE.SPEED ? this.$t('paSpeed') : this.$t('flowRate');
    }
  }
}
</script>

<style scoped lang="scss">
:not(.pa-result-line) .pa-result-line {
  &:nth-child(2n) {
    background: rgba(0, 0, 0, 0.05);
  }

  padding: 6px 15px;
}

.spacer {
  min-height: 18px;
}

.recap {
  margin-bottom: 40px;
}

hr {
  margin: 20px 0;
  border-color: rgba(0,0,0,0.1);
}


.grid-content {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-gap: 20px;

  svg {
    cursor: pointer;
  }
}

.min-img {
  max-height: 100px;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 8px;
}

.input-group {
  margin: 16px 0;

  span {
    display: block;
    margin-bottom: 8px;
    font-size: 1rem;
  }
}

.info {
  display: block;
  margin: 12px 0;
}

.form-control.error {
  border-bottom-color: orangered;

  &:focus {
    border-color: orangered;
  }
}

.input-cbx {
  display: grid;
  grid-template-columns: 26px auto;
  grid-template-areas: 'a b';
  position: relative;

  * {
    cursor: pointer;
  }

  label {
    grid-area: b;
  }

  input {
    grid-area: a;
    border: none;
    position: absolute;

    &::before {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      border: 2px solid #999;
      border-radius: 4px;
      background: white;
      margin: -1px 2px 1px -2px;
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      left: 0;
      background: #0077b3;
      z-index: 3;
      margin: 3px 0 0 2px;
      border-radius: 2px;
      transition: all 300ms ease;
      transform: scale(0);
      opacity: 0;
    }

    &:checked::after {
      transform: scale(1);
      opacity: 1;
    }
  }

}

</style>
