<template>
  <div class="recap" :class="{stepActive: active}">
    <div class="recap-header">
      <div class="label mb-20">{{ $t('msCalculator' )}}</div>
      <span class="total">{{numberize(diameter.diameter, 1)}}</span>
      <span class="unit">mm</span><br>
      <span class="total">{{numberize(diameter.value, 1)}}</span>
      <span class="unit">m/s</span>
    </div>
    <div class="recap-subheader">
      <svg viewBox="0 0 500 100" preserveAspectRatio="xMinYMin meet">
        <defs>
          <linearGradient id="myGradient" gradientTransform="rotate(0)">
            <stop offset="0%" class="gradient1" />
            <stop offset="50%" class="gradient2" />
            <stop offset="100%" class="gradient3" />
          </linearGradient>
        </defs>
        <path d="M0,0 C200,100 350,0 500,50 L500,0 L0,0 Z" style="stroke: none;"></path>
      </svg>
    </div>
    <div class="recap-content">
      <div class="input-group mb-20">
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t('diameter') }} [mm]</th>
              <th>{{ $t('paSpeed') }} [m/s]</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in result" :index="index" :key="item.diameter" :class="{active: item.diameter === diameter.diameter}">
              <td>{{item.diameter}}</td>
              <td>{{numberize(item.value, 1)}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <template v-if="isEnded && !requesting">
        <div class="info-text success" v-if="!errored">
          <p>{{ $t('infoEmailSuccess') }}</p>
        </div>
        <div class="info-text errored" v-else>
          <p>{{ $t('infoEmailErrored') }}</p>
        </div>
      </template>

      <div class="input-group" v-if="!isEnded && !requesting">
        <span>{{  $t('receiveResultsEmail') }}</span>
        <input type="email" id="email" class="form-control" :class="{error: emailError}" :placeholder= "$t('emailAddress')"
               v-model="email"/>
      </div>
      <div class="input-group input-cbx" v-if="!isEnded">
        <label for="cbx">{{ $t('agreeToReceiveFurtherInformationsEmail') }}</label>
        <input type="checkbox" name="cbx" id="cbx" class="cbx" v-model="accept">
      </div>
      <slot />
    </div>
    <div class="recap-footer">
      <back-btn @click="clickBack">{{  $t('goBack') }}</back-btn>
      <ok-btn @click="clickOk" :disabled="isEmailErrored || requesting" v-if="!isEnded">{{ $t('send') }}</ok-btn>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";
import BackBtn from "./parts/back-btn";
import OkBtn from "./parts/ok-btn";
import numberize from '../_helper/numberize.helper';

export default {
  name: "ms-result",
  components: {OkBtn, BackBtn},
  computed: {
    ...mapState({
      active: s => s.ms.step > 0,
      done: s => s.ms.step > 2,
      isEnded: s => s.ms.step === 2,
      requesting: s => s.ms.requesting,
      errored: s => s.ms.hasError
    }),
    result () {
      return this.$store.getters["ms/result"];
    },
    diameter () {
      return this.$store.getters['ms/diameter'];
    },
    isEmailErrored () {
      return this.email.indexOf('@') === -1 || !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email);
    },
    emailed () {
      return false;
    },
    okBtn () {
      return true;
    }
  },
  data () {
    return {
      email: '',
      emailError: false,
      accept: false
    }
  },
  methods: {
    numberize (...args) {
      return numberize(...args);
    },
    clickBack() {
        this.$store.commit('ms/SET_STEP', 0);
    },
    async clickOk() {
      if (this.isEmailErrored) return;
      await this.send({email: this.email, accept: this.accept});
    },
    async send ({email, accept}) {
      if (this.isEnded) return;
      await this.$store.dispatch('ms/send', {
        email: email,
        accept: accept
      })
    },
    async handleBack () {
      this.$store.commit('ms/SET_STEP', 0)
    }
  }
}
</script>

<style scoped lang="scss">

.grid-content {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-gap: 20px;

  svg {
    cursor: pointer;
  }
}

.min-img {
  max-height: 100px;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 8px;
}

.input-group {
  margin: 16px 0;

  span {
    display: block;
    margin-bottom: 8px;
    font-size: 1rem;
  }
}

.info {
  display: block;
  margin: 12px 0;
}

.form-control.error {
  border-bottom-color: orangered;

  &:focus {
    border-color: orangered;
  }
}

.input-cbx {
  display: grid;
  grid-template-columns: 26px auto;
  grid-template-areas: 'a b';
  position: relative;

  * {
    cursor: pointer;
  }

  label {
    grid-area: b;
  }

  input {
    grid-area: a;
    border: none;
    position: absolute;

    &::before {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      border: 2px solid #999;
      border-radius: 4px;
      background: white;
      margin: -1px 2px 1px -2px;
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      left: 0;
      background: #0077b3;
      z-index: 3;
      margin: 3px 0 0 2px;
      border-radius: 2px;
      transition: all 300ms ease;
      transform: scale(0);
      opacity: 0;
    }

    &:checked::after {
      transform: scale(1);
      opacity: 1;
    }
  }

}

table {
  width: 100%;
  border: 2px solid #eee;
  border-radius: 6px;
  td, th {
    padding: .35rem .15rem;
  }
  thead {
    text-align: center;
    th {
      border-bottom: 2px solid #eee;
    }
  }
  tbody {
    text-align: center;
    tr:nth-child(2n) {
      background: rgba(0,0,0,0.05);
    }
    tr.active {
      background: #0077b3;
      color: white;
    }
  }
}
</style>
