<template>
  <div>
    <select v-model="selectedLanguage" @change="switchLanguage" class="select">
      <option v-for="option in languageOptions" :key="option.value" :value="option.value">
        {{ option.label }} {{option.flag}}
      </option>
    </select>


  </div>
</template>

<script>
import 'vue-select/dist/vue-select.css';
// import {CONFIG} from "../../config/config";

export default {
  components: {
  },
  data() {
    return {
      selectedLanguage: this.$i18n.locale,
      languageOptions: [
        { 'value': 'at', 'label': 'Österreichisch', 'flag': '🇦🇹' },
        { 'value': 'bg', 'label': 'Български', 'flag': '🇧🇬' },
        { 'value': 'cz', 'label': 'Čeština', 'flag': '🇨🇿' },
        { 'value': 'dk', 'label': 'Dansk', 'flag': '🇩🇰' },
        { 'value': 'de', 'label': 'Deutsch', 'flag': '🇩🇪' },
        { 'value': 'en', 'label': 'English', 'flag': '🇬🇧' },
        { 'value': 'es', 'label': 'Español', 'flag': '🇪🇸' },
        { 'value': 'fr', 'label': 'Français', 'flag': '🇫🇷' },
        { 'value': 'hu', 'label': 'Magyar', 'flag': '🇭🇺' },
        { 'value': 'nl', 'label': 'Nederlands', 'flag': '🇳🇱' },
        { 'value': 'sk', 'label': 'Slovenčina', 'flag': '🇸🇰' },
        { 'value': 'ro', 'label': 'Românesc', 'flag': '🇷🇴' },
        { 'value': 'pt', 'label': 'Português', 'flag': '🇵🇹' }
      ],
    };
  },
  methods: {
    switchLanguage() {
      this.$i18n.locale = this.selectedLanguage;

      window.history.pushState({}, '', `/${this.selectedLanguage}`);

    },

  },
};
</script>
<style scoped  lang='scss'>
@import '@/style.scss';


 .select {
   background: white;
   padding: 0.5em ;
   border: 1px solid #E4E6EF;
   border-radius: 0.42em;

   &:active, &:focus, &.active {
     outline: none;
     border-color: $primary;
   }


 }
</style>
