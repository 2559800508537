<template>
  <div class="step" :class="{stepActive: active, done: done}">
    <div class="title-language">
      <step-title> {{ $t('paCalculator')}}</step-title>
      <LanguageSwitch />
    </div>
    <div class="form-container">
      <span class="info-text mt-0 mb-20">
        <span class="grid-content">
          <p>
            {{ $t('paDescription') }}
          </p>
          <icon-question-mark v-on:click="openModal"/>
        </span>
      </span>
      <div class="tab-content">
        <ul class="tab-navigation">
          <li v-for="(item, index) in typeValues"
              :index="index"
              :key="item.id"
              :data-label="item.label"
              :data-type="type"
              @click="type = item.label"
              :class="{selected: item.label === type}">
            <template v-if="item.label === PA_TYPES.RECTANGULAR">{{ $t('paRectangular') }}</template>
            <template v-if="item.label === PA_TYPES.CIRCULAR">{{ $t('paCircular') }}</template>
          </li>
        </ul>
        <div class="content-wrapper">
          <div class="active">
            <div class="input-group">
              <span>{{ $t('paValueToCompute') }}</span>
              <select name="processType" id="processType" v-model="processType" class="form-control">
                <option :value="item.label" v-for="(item, index) in processTypeValues" :key="item.key" :index="index">
                  <span v-if="item.label === PROCESS_TYPE.SPEED">Vitesse</span>
                  <span v-if="item.label === PROCESS_TYPE.FLOW">Débit</span>
                </option>
              </select>
            </div>
            <div class="input-group">
              <span>{{ $t('paDensity') }} Kg/m<sup>3</sup></span>
              <input type="number" id="number" class="form-control" v-model="density" step=".1"/>
            </div>
            <div class="input-group" :class="{hidden: processType === PROCESS_TYPE.FLOW}">
              <span>{{ $t('flowRate') }} m<sup>3</sup>/h</span>
              <input type="number" id="number" class="form-control" v-model="flow" step=".1"/>
            </div>
            <div class="input-group" :class="{hidden: processType === PROCESS_TYPE.SPEED}">
              <span>{{ $t('paSpeed') }} m/s</span>
              <input type="number" id="number" class="form-control" v-model="speed" step=".1"/>
            </div>
          </div>
          <div :class="{hidden: type !== PA_TYPES.RECTANGULAR}">
            <div class="content-2">
              <div class="input-group">
                <span>{{ $t('height') }} mm</span>
                <input type="number" id="number" class="form-control" v-model="rectangularHeight" step=".1"/>
              </div>
              <div class="input-group">
                <span>{{ $t('width') }} mm</span>
                <input type="number" id="number" class="form-control" v-model="rectangularWidth" step=".1"/>
              </div>
            </div>
            <div class="content-2">
              <div class="input-group">
                <span>{{ $t('paDownstreamHeight') }} mm</span>
                <input type="number" id="number" class="form-control" v-model="rectangularDownstreamHeight" step=".1"/>
              </div>
              <div class="input-group">
                <span>{{ $t('paDownstreamWidth') }} mm</span>
                <input type="number" id="number" class="form-control" v-model="rectangularDownstreamWidth" step=".1"/>
              </div>
            </div>
          </div>
          <div :class="{hidden: type !== PA_TYPES.CIRCULAR}">
            <div class="input-group">
              <span>{{ $t('diameter') }} mm</span>
              <input type="number" id="number" class="form-control" v-model="circularDiameter" step=".1"/>
            </div>
            <div class="input-group">
              <span>{{ $t('paDownstreamDiameter') }} mm</span>
              <input type="number" id="number" class="form-control" v-model="circularDownstreamDiameter" step=".1"/>
            </div>
          </div>
          <div class="active content-2">
            <div class="input-group">
              <span>{{ $t('paDownstreamFlowRate') }} m<sup>3</sup>/h</span>
              <input type="number" id="number" class="form-control" v-model="downstreamFlow" step=".1"/>
            </div>
            <div class="input-group">
              <span>{{ $t('lateralFlowStream') }} m<sup>3</sup>/h</span>
              <input type="number" id="number" class="form-control" v-model="lateralFlow" step=".1"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-validation">
      <ok-btn @click="handleDone" :disabled="disabled">{{ $t('compute') }}</ok-btn>
    </div>
  </div>
</template>

<script>
import StepTitle from "./parts/step-title";
import IconQuestionMark from "./parts/icon-question-mark";
import OkBtn from "./parts/ok-btn";
import {mapState} from "vuex";
import {PA_TYPES, PROCESS_TYPE} from "../store/pa.store";
import LanguageSwitch from "./parts/language-switch";


export default {
  name: "pa-calculator",
  components: {OkBtn, IconQuestionMark, StepTitle, LanguageSwitch},
  computed: {
    ...mapState({
      active: s => s.pa.step === 0,
      done: s => s.pa.step > 0
    }),
    typeValues() {
      return Object.keys(PA_TYPES).map(key => {
        return {
          id: key,
          label: PA_TYPES[key],
          selected: this.$store.state.pa.type === key
        };
      })
    },
    processTypeValues() {
      return Object.keys(PROCESS_TYPE).map(key => {
        return {
          id: key,
          label: PROCESS_TYPE[key]
        };
      })
    },
    disabled() {
      const keys = [
          'type', 'processType', 'density', 'downstreamFlow', 'lateralFlow'
      ];
      if (this.processType === PROCESS_TYPE.FLOW) {
        keys.push('speed')
      } else {
        keys.push('flow');
      }
      if (this.type === PA_TYPES.CIRCULAR) {
        keys.push('circularDownstreamDiameter', 'circularDiameter')
      } else {
        keys.push('rectangularDownstreamHeight', 'rectangularDownstreamWidth', 'rectangularHeight', 'rectangularWidth')
      }
      for (const k of keys) {
        if (this.$data[k] === null
            || this.$data[k] === undefined
            || this.$data[k] === ''
        ) return true;
      }
      return false;
    }
  },
  data() {
    return {
      PA_TYPES,
      PROCESS_TYPE,
      type: this.$store.state.pa.type,
      processType: this.$store.state.pa.processType,
      density: this.$store.state.pa.density,
      circularDiameter: this.$store.state.pa.circular.diameter,
      flow: this.$store.state.pa.flow,
      speed: this.$store.state.pa.speed,
      rectangularDownstreamHeight: this.$store.state.pa.rectangular.downstreamHeight,
      rectangularDownstreamWidth: this.$store.state.pa.rectangular.downstreamWidth,
      rectangularHeight: this.$store.state.pa.rectangular.height,
      rectangularWidth: this.$store.state.pa.rectangular.width,
      circularDownstreamDiameter: this.$store.state.pa.circular.downstreamDiameter,
      downstreamFlow: this.$store.state.pa.downstreamFlow,
      lateralFlow: this.$store.state.pa.lateralFlow,
    }
  },
  methods: {
    handleDone() {
      const data = {
        type: this.type,
        processType: this.processType,
        density: this.density,
        flow: this.flow,
        speed: this.speed,
        downstreamFlow: this.downstreamFlow,
        lateralFlow: this.lateralFlow,
        circular: {
          diameter: this.circularDiameter,
          downstreamDiameter: this.circularDownstreamDiameter
        },
        rectangular: {
          height: this.rectangularHeight,
          width: this.rectangularWidth,
          downstreamHeight: this.rectangularDownstreamHeight,
          downstreamWidth: this.rectangularDownstreamWidth,
        }
      };
      console.log({data})
      this.$store.commit('pa/SET_VALUES', data)
      this.$store.dispatch('pa/process');
    },
    openModal() {
      this.$store.commit('ms/SET_MODAL_STATE', true);
    }
  }
}
</script>

<style scoped lang="scss">

@import '../style.scss';

.step {
  margin-bottom: 40px;
}
.input-group {
  margin-top: 8px;
  span {
    display: block;
    margin-bottom: 6px;
  }
}
.content-2 {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 10px));
  @media (max-width: 768px) {
    grid-template-columns: auto;
    grid-gap: 0;
  }
  grid-gap: 20px;
}

.content-wrapper {
  div.hidden {
    display: none;
  }
}

ul.tab-navigation {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  margin: 0 0 20px 0;
  padding: 0;

  li {
    list-style: none;
    font-size: 1.2em;
    text-align: center;
    position: relative;
    height: 40px;
    line-height: 40px;
    white-space: nowrap;
    cursor: pointer;

    &:not(.selected) {
      color: #999;
    }

    &.selected::after {
      content: '';
      position: absolute;
      height: 4px;
      width: 50%;
      left: 25%;
      bottom: -2px;


      //background: $gradient;
      background: $primary;

    }
  }
}
</style>
