<template>
  <div>
    <recap-component
        :title="$t('lwlpCalculator')"
        :unit="'dB(A)'"
        :value="displayValue + ''"
        :active="active"
        v-on:back="handleBack"
        v-on:ok="send"
        :hide-content="requesting"
        v-if="!isEnded && hasResult"
    />
    <recap-component
        :title="$t('lwlpCalculator')"
        :unit="'dB(A)'"
        :value="displayValue + ''"
        :active="active"
        v-on:back="handleBack"
        :ok-btn="false"
        :mail-enabled="false"
        v-else-if="hasResult">
      <div class="info-text success" v-if="!errored">
        <p>{{ $t('infoEmailSuccess') }}</p>
      </div>
      <div class="info-text errored" v-else>
        <p>{{ $t('infoEmailErrored') }}</p>
      </div>
    </recap-component>
    <div v-else-if="active" class="info-text errored">
      <p>{{ $t('infoNotComputed') }}</p>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";
import RecapComponent from "./layout/recap-component";

export default {
  name: "lwlp-result",
  components: {RecapComponent},
  computed: {
    ...mapState({
      active: s => s.lwlp.step > 0,
      done: s => s.lwlp.step > 2,
      isEnded: s => s.lwlp.step === 2,
      requesting: s => s.lwlp.requesting,
      errored: s => s.lwlp.hasError
    }),
    displayValue () {
      return this.$store.getters["lwlp/result"];
    },
    hasResult () {
      return !!this.displayValue;
    },
    emailed () {
      return false;
    }
  },
  methods: {
    async send ({email, accept}) {
      if (this.isEnded) return;
      await this.$store.dispatch('lwlp/send', {
        email: email,
        accept: accept,
      })
    },
    async handleBack () {
      this.$store.commit('lwlp/SET_STEP', 0)
    }
  }
}
</script>
