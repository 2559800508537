<template>
  <div id="app" :class="{loading: loading}">
    <div class="header-content">
      <div class="logo">
        <img src="./assets/images/france-air.png" class="france-air-logo" alt="Logo"/>
      </div>
    </div>
    <div class="content">
      <template v-if="calculator === 'dba'">
        <dba-calculator/>
        <dba-result/>
      </template>
      <template v-if="calculator === 'lwlp'">
        <lwlp-calculator />
        <lwlp-result />
      </template>
      <template v-if="calculator === 'ms'">
        <ms-calculator />
        <ms-result />
        <modal-ms />
      </template>
      <template v-if="calculator === 'pa'">
        <pa-calculator />
        <pa-result />
        <modal-ms />
      </template>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import DbaCalculator from "./components/dba-calculator";
import DbaResult from "./components/dba-result";
import LwlpCalculator from "./components/lwlp-calculator";
import LwlpResult from "./components/lwlp-result";
import MsCalculator from "./components/ms-calculator";
import MsResult from "./components/ms-result";
import ModalMs from "./components/parts/modal-ms";
import PaCalculator from "./components/pa-calculator";
import PaResult from "./components/pa-result";



export default {
  name: 'App',
  components: {
    PaResult,
    PaCalculator,
    ModalMs,
    MsResult,
    MsCalculator,
    LwlpResult,
    LwlpCalculator,
    DbaResult,
    DbaCalculator,

  },
  computed: {
    ...mapState({
      calculator: s => s.calculator,
      loading: (state, getters) => getters.loading
    })
  }
}
</script>


<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import './style.scss';

//$primary: #0077b3;
//$danger: #ba0000;

body {
  max-width: 100vw;
  overflow-x: hidden;
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.grid-content {
  display: grid;
  grid-template-columns: auto min-content;
  grid-gap: .6rem;
  align-items: center;
}


.loading::after {
  content: '';
  background: rgba(255, 255, 255, .6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.help-item {
  font-style: italic;
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input, input:before, input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

.france-air--container {
  display: flex;
  margin-top: 10px;

  .france-air-logo {
    flex: 0 0 auto;
    height: 40px;
    margin: auto;
    @media (max-width: 768px) {
      height: 30px;
    }
  }
}


body {
  //position: fixed;
  //top: 0;
  //left: 0;
  //right: 0;
  //bottom: 0;
  border-top: 2px solid #d4d4d4;


}

#app {
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 3px;
    background: $primary;
  }
}


body, html {
  font-family: 'Twemoji Country Flags','Lato', sans-serif;
  padding: 0;
  margin: 0;
  background: #f4f6f4;
  color: #444;

  .header-content {
    display: flex;
    flex-wrap: nowrap;
    padding: 40px 0;
    @media (max-width: 768px) {
      padding: 20px 0;
    }

    .logo {
      flex: 0 0 auto;
      margin: 0 auto;

      img {
        height: 80px;
        width: auto;
        @media (max-width: 768px) {
          height: 40px;
        }
      }
    }
  }
}

.btn {
  background: transparentize($primary, .8);
  border: none;
  border-radius: 6px;
  padding: 7px 14px;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;

  span {
    flex: 1 1 auto;
    margin: auto auto auto 0;
  }

  svg {
    flex: 0 0 auto;
    margin: auto 0 auto auto;
  }

  grid-gap: 14px;
  align-items: center;
  transition: all 300ms ease;
  position: relative;
  cursor: pointer;

  &:active, &:focus {
    outline: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparentize($primary, .8);
    transition: all 300ms ease;
    opacity: 0;
    transform-origin: left center;
    transform: scaleX(0) translateX(-100%);
  }

  &:hover::before {
    opacity: 1;
    transform: scaleX(1) translateX(0);
  }

  &::before {
    opacity: 0;
    transform: scaleX(0) translateX(-100%);
  }

  span {
    font-weight: 500;
    letter-spacing: 1.1px;
    color: $primary;
    font-size: 1.1em;
  }

  svg {
    height: 20px;

    * {
      fill: $primary;
    }
  }

  &.back-btn {
    span {
      flex: 1 1 auto;
      margin: auto 0 auto auto;
    }

    svg {
      flex: 0 0 auto;
      margin: auto auto auto 0;
    }
  }

  &:disabled {
    cursor: not-allowed;
    background: transparentize(#444, .8);

    &::before {
      background: transparent;
    }

    span {
      color: #444;
    }

    svg * {
      fill: #444;
    }
  }
}

.content {
  width: 680px;
  margin: 0 auto;
  position: relative;
  @media (max-width: 768px) {
    width: 80%;
  }
}


.step, .recap {
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  background: white;
  transform-origin: center center;
  transition: all 300ms ease;
  transform: translateX(100vw) scale(0);
  opacity: 0;

  &.stepActive {
    transform: translateX(0) scale(1);
    opacity: 1;
    position: relative;
  }

  &.done {
    transform: translateX(-100vw) scale(0);
    opacity: 0;
  }
}

$primary: #0077b3;
.recap {
  padding: 20px;
  overflow: hidden;
  transition: all 500ms ease;


  .recap-header {
    margin: -20px -20px 40px -20px;
    padding: 40px 40px 0 40px;
    background: $primary;
    //background: $gradient;
    color: white;
    text-align: center;

    .label {
      font-size: 1.2em;
      opacity: .5;
    }

    span {
      font-size: 3em;

      &.unit {
        margin-left: 12px;
        font-size: 2em;
        color: rgba(255, 255, 255, .5);
      }
    }
  }

  .recap-subheader {
    margin: -40px -20px -20px;

    svg path {
        fill: $primary;
      }
  }

  .recap-footer {
    margin-top: 20px;
    display: flex;

    .btn:first-child {
      margin: auto auto auto 0;
    }

    .btn:last-child {
      margin: auto 0 auto auto;
    }

    @media (max-width: 500px) {
      display: block;
      .btn {
        margin: 10px auto auto auto !important;
        width: 100%;
      }
    }
  }
}

.step {
  padding: 20px;
  display: grid;
  grid-gap: 20px;

  .step-title {
    font-size: 1.4em;
    font-weight: 600;
    @media (max-width: 768px) {
      font-size: 1.1em;
    }
  }

  .form-validation {
    display: flex;

    .btn {
      flex: 0 0 auto;
      margin: auto 0 auto auto;
    }
  }
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.info-text {
  display: block;
  padding: 0.65rem 1rem;
  border: 1px solid #E4E6EF;
  border-radius: 0.42rem;
  margin: 10px 0 10px 20px;
  color: #3F4254;
  position: relative;
  font-weight: 400;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    bottom: 0;
    width: 4px;
    background: transparentize($primary, .7);
    //background: $primary;
    border-radius: 2px;
  }

  &.errored::before {
    background: transparentize($danger, .7);
  }

  &.errored {
    border-color: transparentize($danger, .7);
    color: $danger;
  }

  &.success::before {
    background: transparentize($primary, .7);
  }

  &.success {
    border-color: transparentize($primary, .7);
    color: $primary;
  }

  p {
    font-size: .8em;
  }
}

.form-control {
  display: block;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  width: calc(100% - 2rem);
  color: #3F4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  border-radius: 0.42rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  cursor: pointer;

  &:active, &:focus, &.active {
    outline: none;
    border-color: $primary;
  }
}

select.form-control {
  width: 100%!important;
}

.form-control:active, .form-control:focus, {
  outline: none;
  border-color: $primary;
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

$scales: [0px, 5px, 10px, 15px, 20px, 25px, 30px, 35px, 40px];
@each $scale in $scales {
  .mt-#{strip-unit($scale)} {
    margin-top: $scale;
  }
  .mr-#{strip-unit($scale)} {
    margin-right: $scale;
  }
  .mb-#{strip-unit($scale)} {
    margin-bottom: $scale;
  }
  .ml-#{strip-unit($scale)} {
    margin-left: $scale;
  }
  .m-#{strip-unit($scale)} {
    margin: $scale;
  }
}

.title-language {
  display: flex;
  justify-content: space-between;
}

</style>
