<template>
  <div class="pa-result-line pa-container">
    <div class="label">{{label}}</div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "pa-container",
  props: {
    label: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.label {
  font-weight: 600;
  margin-bottom: 6px;
}
</style>
