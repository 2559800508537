<template>
  <div class="step" :class="{stepActive: active, done: done}">
    <div class="title-language">
      <step-title>{{ $t('dbaCalculator') }}</step-title>
      <LanguageSwitch />
    </div>
    <div class="form-container">
      <span class="info-text mt-0 mb-20">
        <p>
          {{ $t('dbaDescription') }}
        </p>
      </span>
      <div class="input-group">
        <span>Lw 1 dB(A)</span>
        <input type="number" id="number" class="form-control" v-model="lw1" step=".1"/>
      </div>
      <div class="input-group mt-20">
        <span>Lw 2 dB(A)</span>
        <input type="number" id="number" class="form-control" v-model="lw2" step=".1"/>
      </div>
    </div>
    <div class="form-validation">
      <ok-btn @click="handleDone" :disabled="disabled">{{ $t('compute')}}</ok-btn>
    </div>
  </div>
</template>

<script>
import OkBtn from "./parts/ok-btn";
import {mapState} from "vuex";
import StepTitle from "./parts/step-title";
import LanguageSwitch from "./parts/language-switch";

export default {
  name: "dba-calculator",
  components: {StepTitle, OkBtn, LanguageSwitch},
  data() {
    return {
      lw1: 0,
      lw2: 0
    }
  },
  watch: {
    lw1(v) {
      this.$store.commit('dba/SET_LW1', v)
    },
    lw2(v) {
      this.$store.commit('dba/SET_LW2', v)
    }
  },
  computed: {
    ...mapState({
      active: s => s.dba.step === 0,
      done: s => s.dba.step > 0
    }),
    disabled() {
      return false;
    },
    done() {
      return false;
    }
  },
  methods: {
    handleDone() {
      this.$store.commit('dba/SET_STEP', 1);
    }
  }
}
</script>

<style scoped>

</style>
