<template>
  <div class="pa-result-line pa-simple" :class="{hasUnit: !!unit}">
    <span class="label">{{label}}</span>
    <template v-if="unit">
      <span class="value">{{value}}</span>
      <span class="unit" v-html="unit"></span>
    </template>
    <template v-else>
      <span class="value">{{value}}</span>
    </template>
  </div>
</template>

<style scoped lang="scss">
  .pa-simple {
    display: grid;
    grid-template-columns: 50% calc(50% - 20px);
    grid-gap: 20px;
    &.hasUnit {
      grid-template-columns: 50% max-content min-content;
    }
    .label {
      font-weight: 600;
    }
    .value {
      color: #0077b3;
      font-size: 1.1em;
      font-weight: 400;
    }
    .unit {
      font-size: .9em;
      color: #999;
      font-weight: 300;
    }
  }
</style>

<script>
export default {
  name: "pa-result-simple",
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      required: true
    },
    unit: {
      type: String,
      required: false
    }
  }
}
</script>

