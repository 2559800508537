<template>
  <div class="step" :class="{stepActive: active, done: done}">
    <div class="title-language">
      <step-title>{{  $t('lwlpCalculator') }} </step-title>
      <LanguageSwitch />
    </div>
    <div class="form-container">
      <span class="info-text mt-0 mb-20">
        <p>
          {{ $t('lwlpDescription')}}
        </p>
      </span>
      <div class="input-group">
        <span>Lw dB(A)</span>
        <input type="number" id="number" class="form-control" v-model="lw" step=".1"/>
      </div>
      <div class="input-group mt-20">
        <span>{{$t('lwlpDistance')}}</span>
        <select name="distance" id="distance" v-model="distance" class="form-control">
          <option
              v-for="(item, index) in inputDistance"
              :index="index"
              :key="item.value"
              :value="item.value">{{item.label}}</option>
        </select>
      </div>
      <div class="input-group mt-20">
        <span>{{ $t('lwlpDirectivity')}}</span>
        <select name="directivity" id="directivity" v-model="directivity" class="form-control">
          <option
              v-for="(item, index) in directivityOptions"
              :index="index"
              :key="item.value"
              :value="item.value">{{item.label}}</option>
        </select>
      </div>
    </div>
    <div class="form-validation">
      <ok-btn @click="handleDone" :disabled="disabled">{{ $t('compute') }}</ok-btn>
    </div>
  </div>
</template>

<script>
import StepTitle from "./parts/step-title";
import OkBtn from "./parts/ok-btn";
import {mapState} from "vuex";
import LanguageSwitch from "./parts/language-switch";


export default {
  name: "lwlp-calculator",
  components: {StepTitle, OkBtn, LanguageSwitch},
  data() {
    return {
      lw: 70.0,
      distance: 'NONE',
      directivity: 'NONE',
      directivityOptions: [],

    }
  },
  watch: {
    lw(v) {
      this.$store.commit('lwlp/SET_LW', v)
    },
    distance(v) {
      this.$store.commit('lwlp/SET_DISTANCE', v)
    },
    directivity(v) {
      this.$store.commit('lwlp/SET_DIRECTIVITY', v)
    },
    '$i18n.locale': 'updateDirectivityOptions',
  },
  beforeMount() {
    this.updateDirectivityOptions(); // Initial update
  },
  computed: {
    ...mapState({
      inputDistance: s => s.lwlp.input.distance,
      inputDirectivity: s => s.lwlp.input.directivity,
      active: s => s.lwlp.step === 0,
      done: s => s.lwlp.step > 0
    }),
    disabled() {
      return !this.$store.getters['lwlp/result'];
    },
    done() {
      return false;
    }
  },
  methods: {
    updateDirectivityOptions() {
      this.directivityOptions = this.inputDirectivity.map((item) => ({
        ...item,
        label: this.$t(item.label),
      }));
    },
    handleDone() {
      if (this.disabled) return;
      this.$store.commit('lwlp/SET_STEP', 1);
    }
  }
}
</script>

<style scoped>

</style>
