import Vue from "vue";
import VueI18n from "vue-i18n";
const {CONFIG} = require("@/config/config");

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: CONFIG.default_lang,
  messages: {
    fr: require('../locales/fr.json'),
    en: require('../locales/en.json'),
    at: require('../locales/at.json'),
    bg: require('../locales/bg.json'),
    cz: require('../locales/cz.json'),
    de: require('../locales/de.json'),
    dk: require('../locales/dk.json'),
    es: require('../locales/es.json'),
    hu: require('../locales/hu.json'),
    nl: require('../locales/nl.json'),
    sk: require('../locales/sk.json'),
    ro: require('../locales/ro.json'),
    pt: require('../locales/pt.json'),

  },

});

export default i18n;
