<template>
  <div class="step-title">
    <slot />
  </div>
</template>

<script>
export default {
  name: "step-title"
}
</script>

<style scoped>

</style>
