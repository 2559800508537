<template>
  <div class="recap" :class="{stepActive: active}">
    <div class="recap-header">
      <div class="label">{{ title }}</div>
      <span class="total">{{ value | numberize(1) }}</span>
      <span class="unit">{{ unit }}</span>
    </div>
    <div class="recap-subheader">
      <svg viewBox="0 0 500 100" preserveAspectRatio="xMinYMin meet">
        <path d="M0,0 C200,100 350,0 500,50 L500,0 L0,0 Z" style="stroke: none;"></path>
      </svg>
    </div>
    <div class="recap-content" v-if="!hideContent">
      <div class="input-group" v-if="mailEnabled">
        <span>{{ $t('receiveResultsEmail') }}</span>
        <input type="email" id="email" class="form-control" :class="{error: emailError}" :placeholder="$t('emailAddress')"
               v-model="email"/>
      </div>
      <div class="input-group input-cbx" v-if="mailEnabled">
        <label for="cbx">{{ $t('agreeToReceiveFurtherInformationsEmail') }}</label>
        <input type="checkbox" name="cbx" id="cbx" class="cbx" v-model="accept">
      </div>
      <slot />
    </div>
    <div class="recap-footer" v-if="!hideContent">
      <back-btn @click="clickBack">{{ $t('goBack') }}</back-btn>
      <ok-btn @click="clickOk" :disabled="errored" v-if="okBtn">{{ $t('send') }}</ok-btn>
    </div>
  </div>
</template>
<script>
import BackBtn from "../parts/back-btn";
import OkBtn from "../parts/ok-btn";

export default {
  name: "recap-component",
  props: {
    title: {
      type: String,
      required: true
    },
    unit: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    backText: {
      type: String,
      default: "Back",
      required: false
    },
    okText: {
      type: String,
      default: "Send by email",
      required: false
    },
    active: {
      type: Boolean,
      require: false,
      default: false
    },
    hideContent: {
      type: Boolean,
      default: false
    },
    mailEnabled: {
      type: Boolean,
      default: true
    },
    okBtn: {
      type: Boolean,
      default: true
    }
  },
  components: {OkBtn, BackBtn},
  data() {
    return {
      email: '',
      accept: false
    }
  },
  computed: {
    emailError() {
      return this.email.indexOf('@') !== -1 && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email);
    },
    errored () {
      return this.emailError || this.email.length === 0
    }
  },
  methods: {
    clickBack(e) {
      this.$emit('back', e);
    },
    clickOk() {
      if (this.errored) return;
      this.$emit('ok', {
        email: this.email,
        accept: this.accept
      });
    }
  }
}
</script>

<style scoped lang="scss">
.grid-content {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-gap: 20px;

  svg {
    cursor: pointer;
  }
}

.min-img {
  max-height: 100px;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 8px;
}

.input-group {
  margin: 16px 0;

  span {
    display: block;
    margin-bottom: 8px;
    font-size: 1rem;
  }
}

.info {
  display: block;
  margin: 12px 0;
}

.form-control.error {
  border-bottom-color: orangered;

  &:focus {
    border-color: orangered;
  }
}

.input-cbx {
  display: grid;
  grid-template-columns: 26px auto;
  grid-template-areas: 'a b';
  position: relative;

  * {
    cursor: pointer;
  }

  label {
    grid-area: b;
  }

  input {
    grid-area: a;
    border: none;
    position: absolute;

    &::before {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      border: 2px solid #999;
      border-radius: 4px;
      background: white;
      margin: -1px 2px 1px -2px;
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      left: 0;
      background: #0077b3;
      z-index: 3;
      margin: 3px 0 0 2px;
      border-radius: 2px;
      transition: all 300ms ease;
      transform: scale(0);
      opacity: 0;
    }

    &:checked::after {
      transform: scale(1);
      opacity: 1;
    }
  }
}
</style>
