<template>
  <div class="step" :class="{stepActive: active, done: done}">
    <div class="title-language">
      <step-title>{{ $t('msCalculator') }}</step-title>
      <LanguageSwitch />
    </div>
    <div class="form-container">
      <span class="info-text mt-0 mb-20">
        <span class="grid-content">
          <p>
            {{ $t('msDescription') }}
          </p>
          <icon-question-mark v-on:click="openModal"/>
        </span>
      </span>
      <div class="input-group">
        <span>{{ $t('flowRate') }} m<sup>3</sup>/h</span>
        <input type="number" id="number" class="form-control" v-model="flowRate" step=".1"/>
      </div>
      <div class="input-group mt-20">
        <span>{{ $t('msMaxSpeedExpected') }} m/s</span>
        <input type="number" id="number" class="form-control" v-model="maxSpeedExpected" step=".1"/>
      </div>
    </div>
    <div class="form-validation">
      <ok-btn @click="handleDone" :disabled="disabled">{{ $t('compute')}}</ok-btn>
    </div>
  </div>
</template>

<script>
import StepTitle from "./parts/step-title";
import OkBtn from "./parts/ok-btn";
import {mapState} from "vuex";
import IconQuestionMark from "./parts/icon-question-mark";
import LanguageSwitch from "./parts/language-switch";

export default {
  name: "ms-calculator",
  components: {IconQuestionMark, StepTitle, OkBtn, LanguageSwitch},
  data() {
    return {
      maxSpeedExpected: 4,
      flowRate: 0,
    }
  },
  watch: {
    flowRate(v) {
      this.$store.commit('ms/SET_FLOW_RATE', v)
    },
    maxSpeedExpected(v) {
      this.$store.commit('ms/SET_MAX_SPEED', v)
    }
  },
  computed: {
    ...mapState({
      active: s => s.ms.step === 0,
      done: s => s.ms.step > 0
    }),
    disabled() {
      return this.flowRate <= 0 || this.maxSpeedExpected <= 0;
    },
    done() {
      return false;
    }
  },
  methods: {
    handleDone() {
      if (this.disabled) return;
      this.$store.commit('ms/SET_STEP', 1);
    },
    openModal () {
      this.$store.commit('ms/SET_MODAL_STATE', true);
    },
  }
}
</script>

