import numberize from "../_helper/numberize.helper";
import axios from "axios";
import {CONFIG} from "@/config/config";

export default function(i18n) {
    return {
        namespaced: true,
        state: {
            input: {
                distance: [
                    {value: 'NONE', label: '-'},
                    {value: 1, label: '1'},
                    {value: 2, label: '2'},
                    {value: 3, label: '3'},
                    {value: 4, label: '4'},
                    {value: 5, label: '5'},
                    {value: 6, label: '6'},
                ],
                directivity: [
                    {value: 'NONE', label: '-'},
                    {value: 2, label: 'lwlpQ2'},
                    {value: 4, label: 'lwlpQ4'},
                    {value: 8, label: 'lwlpQ8'},
                ]
            },
            distance: 'NONE',
            directivity: 'NONE',
            lw: 70.0,
            step: 0,
            requesting: false,
            hasError: false
        },
        getters: {
            result: state => {
                if (state.distance === 'NONE' || state.directivity === 'NONE')
                    return null;
                const interm = 10 * Math.log(state.directivity / (4 * Math.PI * Math.pow(state.distance, 2))) / Math.log(10);
                return Math.round((state.lw - Math.abs(interm)) * 10) / 10;
            },
            distanceLabel: state => {
                const results = state.input.distance.filter(s => s.value === state.distance);
                if (results.length === 0) return state.input.distance[0].label;
                return results[0].label;
            },
            directivityLabel: state => {
                const results = state.input.directivity.filter(s => s.value === state.directivity);
                if (results.length === 0) return i18n.t(state.input.directivity[0].label);
                return i18n.t(results[0].label);
            },
        },
        mutations: {
            SET_DISTANCE(state, value) {
                state.distance = value;
            },
            SET_DIRECTIVITY(state, value) {
                state.directivity = value;
            },
            SET_LW(state, value) {
                state.lw = value;
            },
            SET_STEP(state, payload) {
                state.step = payload ? parseInt(payload) : 0;
            },
            SET_REQUESTING(state, payload) {
                state.requesting = !!payload;
                state.hasError = false;
            },
            SET_ERROR(state, payload) {
                state.hasError = !!payload;
            }
        },
        actions: {
            async send(context, {email, accept}) {
                try {

                    const children = {
                        "Lw": `${numberize(context.state.lw, 1)} dB(A)`,
                        "distance": `${context.getters.distanceLabel} m`,
                        "lwlpDirectivity": `${context.getters.directivityLabel}`,
                    };

                    const translatedChildren = {};
                    Object.keys(children).forEach(key => {
                        translatedChildren[i18n.t(key)] = children[key];
                    });

                    const json = {
                        type: "lwlp",
                        provider: "web",
                        title: i18n.t('lwlpCalculatorResults'),
                        unit: "",
                        value: numberize(context.getters.result, 1) + ' dB(A)',
                        parts: [
                            {
                                title: i18n.t('data'),
                                children: translatedChildren,
                                // children: {
                                //     "Lw": `${numberize(context.state.lw, 1)} dB(A)`,
                                //     "Distance": `${context.getters.distanceLabel} m`,
                                //     "Directivité": `${context.getters.directivityLabel}`,
                                // }
                            },
                            {
                                title: i18n.t('result'),
                                children: {
                                    'Lp': `${numberize(context.getters.result, 1)} dB(A)`
                                }
                            }
                        ],
                        footer: i18n.t('emailFooter'),
                        send: {
                            email: email,
                            accept: accept
                        },
                        pdf: [
                            {
                                type: "group",
                                title: i18n.t('lwlpCalculatorResults'),
                                marginIncrement: 10,
                                child: [
                                    {
                                        type: "square",
                                        title: i18n.t('data'),
                                        data: [
                                            {
                                                type: "text",
                                                name: "Lw",
                                                value: `${numberize(context.state.lw, 1)} dB(A)`
                                            },
                                            {
                                                type: "text",
                                                name: i18n.t('distance'),
                                                value: `${context.getters.distanceLabel} m`
                                            },
                                            {
                                                type: "text",
                                                name: i18n.t('lwlpDirectivity'),
                                                value: `${context.getters.directivityLabel}`
                                            }
                                        ]
                                    },
                                    {
                                        type: "square",
                                        title: i18n.t('results'),
                                        data: [
                                            {
                                                type: "text",
                                                fontSize: 3,
                                                bold: true,
                                                name: "Lp",
                                                value: `${numberize(context.getters.result, 1)} dB(A)`
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    };

                    context.commit('SET_REQUESTING', true);
                    const response = await axios.post(CONFIG.api + '/pdf/mail/calculateur', json);
                    context.commit('SET_REQUESTING', false);
                    if (!response || response.code >= 300) {
                        context.commit('SET_ERROR', true);
                    }
                    context.commit('SET_STEP', 2);
                    return response;
                } catch (error) {
                    console.error('Error in send action:', error);
                }
            }
        }
    }
}
