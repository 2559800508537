import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex';
import {store} from './store'
import numberize from './_helper/numberize.helper';
import i18n from "@/translations";
import CountryFlag from 'vue-country-flag';
import {CONFIG} from "@/config/config";


Vue.use(Vuex);
Vue.component('country-flag', CountryFlag)
Vue.config.productionTip = false


Vue.filter('numberize', numberize);

new Vue({
  render: h => h(App),
  i18n,
  store,
  watch: {
    '$i18n.locale': 'updateTitle',
  },
  methods: {
    updateTitle() {
      document.title = this.pageTitle;
    },
  },
  computed: {
    pageTitle() {
      return 'France Air - ' + this.$i18n.t(CONFIG.calculator + 'Calculator');
    },
  },
  mounted () {
    this.updateTitle();
  },
  beforeCreate() {
    const segments = window.location.pathname.split('/');
    console.log(segments[1]);
    const locale = segments[1];
    console.log(Object.keys(i18n.messages));
    console.log(Object.keys(i18n.messages).includes(locale));
    // Assuming the locale is the first segment
    // Check if the locale is supported by your i18n configuration
    if (Object.keys(i18n.messages).includes(locale)) {
      this.$i18n.locale = locale; // Set the locale
      window.history.pushState({}, '', `/${this.$i18n.locale}`);

    } else {
      // If the locale is not found, redirect to the default locale
      window.location.replace(`/${i18n.locale}${window.location.pathname}`);
    }

  }
}).$mount('#app')
