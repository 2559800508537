import numberize from "../_helper/numberize.helper";
import axios from "axios";
import {CONFIG} from "../config/config";

export const PA_TYPES = {
    CIRCULAR: 'circular',
    RECTANGULAR: 'rectangular'
}

export const PROCESS_TYPE = {
    SPEED: 'speed',
    FLOW: 'flow'
}

export default function (i18n) {
    return {
        namespaced: true,
        state: {
            requesting: false,
            hasError: false,
            result: null,
            step: 0,
            type: PA_TYPES.CIRCULAR,
            processType: PROCESS_TYPE.SPEED,
            density: 1.2,
            flow: null,
            speed: null,
            rectangular: {
                downstreamHeight: null,
                downstreamWidth: null,
                height: null,
                width: null
            },
            circular: {
                downstreamDiameter: null,
                diameter: null,
            },
            downstreamFlow: null,
            lateralFlow: null
        },
        getters: {
            flow: s => {
                if (s.result === null) return null;
                return s.result.debit_round;
            },
            speed: s => {
                if (s.result === null) return null;
                return s.result.vitesse_round;
            },
            linearPressureLoss: s => {
                if (s.result === null) return null;
                return s.result.perte_charge_lineaire;
            },
            coude30: s => {
                return s.result === null ? null : s.result.coude30;
            },
            coude45: s => {
                return s.result === null ? null : s.result.coude45;
            },
            coude90: s => {
                return s.result === null ? null : s.result.coude90;
            },
            lateral90: s => {
                return s.result === null ? null : s.result.lateral90;
            },
            souche: s => {
                return s.result === null ? null : s.result.souche;
            },
            lateralTee90: s => {
                return s.result === null ? null : s.result.t_lat;
            },
            straightTee90: s => {
                return s.result === null ? null : s.result.t_recti;
            },
            output: s => {
                if (s.result === null) return null;
                return s.result.sortie;
            },
            abruptExpansion: s => {
                if (s.result === null) return null;
                return s.result.elarg_brusque;
            },
            conicExpansion: s => {
                if (s.result === null) return null;
                return s.result.elarg_conique;
            },
            abruptReduction: s => {
                if (s.result === null) return null;
                return s.result.reduc_brusque;
            },
            conicReduction: s => {
                if (s.result === null) return null;
                return s.result.reduc_conique;
            },
        },
        mutations: {
            SET_REQUESTING(state, payload) {
                state.requesting = !!payload;
                state.hasError = false;
            },
            SET_ERROR(state, payload) {
                state.hasError = !!payload;
            },
            SET_VALUES(state, payload) {
                Object.keys(payload).forEach((key) => {
                    if (typeof payload[key] === 'object' && payload[key] !== null) {
                        Object.keys(payload[key]).forEach((subKey) => {
                            state[key][subKey] = payload[key][subKey];
                        })
                        return;
                    }
                    state[key] = payload[key];
                })
            },
            SET_RESULT(state, payload) {
                state.result = payload;
            },
            SET_STEP(state, payload) {
                state.step = payload;
            }
        },
        actions: {
            process(context) {

                let masse = context.state.density;
                let hauteur_ou_diametre = context.state.type === PA_TYPES.RECTANGULAR ? context.state.rectangular.height : context.state.circular.diameter;
                let largeur = context.state.type === PA_TYPES.RECTANGULAR ? context.state.rectangular.width : null;
                let debit = context.state.flow;
                let vitesse = context.state.speed;
                let elarg_amont_ou_hauteur_aval = context.state.type === PA_TYPES.RECTANGULAR ? context.state.rectangular.downstreamHeight : context.state.circular.diameter;
                let elarg_aval_ou_largeur_aval = context.state.type === PA_TYPES.RECTANGULAR ? context.state.rectangular.downstreamWidth : context.state.circular.downstreamDiameter;
                let rectiligne_aval = context.state.downstreamFlow;
                let rectiligne_lateral = context.state.lateralFlow;

                let hauteur_sauvegarde = hauteur_ou_diametre;
                let hauteur_cube, largeur_cube, interm, diametre, elarg_amont, elarg_aval, vitesse_round, debit_round,
                    vitesse2, perte_charge_lineaire, coude30, coude45, coude90, elarg_brusque, elarg_conique,
                    intermediaire, intermediaire1, intermediaire_lat,
                    intermediaire_recti, lateral90, t_recti, reduc_brusque, reduc_conique, sortie, souche, t_lat

                if (largeur != null) { // Si rectangle
                    hauteur_cube = Math.pow(hauteur_ou_diametre, 3);
                    largeur_cube = Math.pow(largeur, 3);
                    interm = Math.pow((hauteur_cube * largeur_cube) / (parseFloat(hauteur_ou_diametre) + parseFloat(largeur)), 0.20);
                    diametre = 1.265 * interm;

                    hauteur_ou_diametre = diametre;

                    elarg_amont = hauteur_ou_diametre;
                    elarg_aval = 50;

                    hauteur_cube = Math.pow(elarg_amont_ou_hauteur_aval, 3);
                    largeur_cube = Math.pow(elarg_aval_ou_largeur_aval, 3);
                    interm = Math.pow((hauteur_cube * largeur_cube) / (parseFloat(elarg_amont_ou_hauteur_aval) + parseFloat(elarg_aval_ou_largeur_aval)), 0.20);
                    elarg_aval = 1.265 * interm;
                } else {
                    elarg_amont = elarg_amont_ou_hauteur_aval;
                    elarg_aval = elarg_aval_ou_largeur_aval;
                }

                if (vitesse == null) { // CALCUL VITESSE
                    if (largeur != null) { // Si rectangle
                        vitesse = (debit / 3600 / largeur / hauteur_sauvegarde) * 1000000;
                    } else {
                        vitesse = debit / 3600;
                        vitesse = vitesse / Math.pow((hauteur_ou_diametre / 2000), 2);
                        vitesse = vitesse / Math.PI;
                    }
                } else { //CALCUL DEBIT

                    if (largeur != null) { // Si rectangle
                        debit = vitesse * hauteur_sauvegarde * largeur * 3600;
                        debit = debit * (Math.pow(10, -6));
                    } else {
                        debit = vitesse * 3600 * Math.PI;
                        debit = debit * (Math.pow((hauteur_ou_diametre / 2000), 2));
                    }
                }

                if (masse != null && hauteur_ou_diametre != null && debit != null && vitesse != null) {

                    vitesse_round = Math.round(vitesse * 10) / 10;
                    debit_round = Math.round(debit * 10) / 10;

                    perte_charge_lineaire = null;
                    if (largeur != null) { // Si rectangle

                        let inter = (((2 * hauteur_sauvegarde * largeur) / (parseFloat(hauteur_sauvegarde) + parseFloat(largeur))) / 2000);
                        inter = Math.pow(inter, 2) * Math.PI;
                        inter = Math.pow(inter, -0.6088863039);

                        perte_charge_lineaire = inter * (Math.pow((vitesse / 9), 2) * masse / 1.2) * 0.6302811256;
                        perte_charge_lineaire = Math.round(perte_charge_lineaire * 10) / 10;
                        //Vitesse 2 va servir pour la suite
                        vitesse2 = debit / ((Math.PI * Math.pow(hauteur_ou_diametre, 2)) / 4) / (Math.pow(10, -6)) / 3600;

                    } else { // Cercle

                        let inter = Math.pow((hauteur_ou_diametre / 2000), 2) * Math.PI;
                        inter = Math.pow(inter, -0.6088863039);
                        perte_charge_lineaire = inter * (Math.pow((vitesse / 9), 2) * masse / 1.2) * 0.6302811256;
                        perte_charge_lineaire = Math.round(perte_charge_lineaire * 10) / 10;
                        //Vitesse 2 va servir pour la suite
                        vitesse2 = vitesse;
                    }
                    //FIN PERTE DE CHARGE LINEAIRE

                    coude30 = null;
                    coude30 = 0.12 * 0.5 * masse * Math.pow(vitesse2, 2);
                    coude30 = Math.round(coude30 * 10) / 10;

                    coude45 = null;
                    coude45 = 0.17 * 0.5 * masse * Math.pow(vitesse2, 2);
                    coude45 = Math.round(coude45 * 10) / 10;

                    coude90 = null;
                    coude90 = 0.29 * 0.5 * masse * Math.pow(vitesse2, 2);
                    coude90 = Math.round(coude90 * 10) / 10;

                    lateral90 = null;
                    lateral90 = 1.4 * 0.5 * masse * Math.pow(vitesse2, 2);
                    lateral90 = Math.round(lateral90 * 10) / 10;

                    souche = null;
                    souche = 2 * 0.5 * masse * Math.pow(vitesse2, 2);
                    souche = Math.round(souche * 10) / 10;

                    sortie = null;
                    sortie = 0.5 * masse * Math.pow(vitesse2, 2);
                    sortie = Math.round(sortie * 10) / 10;

                    elarg_brusque = null;
                    intermediaire = null;

                    if (parseFloat(elarg_amont) < parseFloat(elarg_aval)) {
                        intermediaire = 0.8304 * Math.pow((elarg_amont / elarg_aval), 4) + 0.3895 * Math.pow((elarg_amont / elarg_aval), 3) - 2.3011 * Math.pow((elarg_amont / elarg_aval), 2) + 0.0895 * (elarg_amont / elarg_aval) + 0.9925;
                        elarg_brusque = intermediaire * 0.5 * masse * Math.pow(vitesse2, 2);
                        elarg_brusque = Math.round(elarg_brusque * 10) / 10;
                    }

                    elarg_conique = null;
                    intermediaire = null;

                    if (parseFloat(elarg_amont) < parseFloat(elarg_aval)) {
                        intermediaire = Math.pow((elarg_amont / elarg_aval), 2);
                        intermediaire = 1.12 * Math.pow((1 - intermediaire), 2);
                        intermediaire = intermediaire * 0.5 * masse * Math.pow(vitesse2, 2);

                        elarg_conique = Math.round(intermediaire * 10) / 10;
                    }

                    reduc_brusque = null;
                    intermediaire = null;
                    intermediaire1 = null;

                    if (parseFloat(elarg_amont) > parseFloat(elarg_aval)) {
                        intermediaire = ((Math.PI * Math.pow(elarg_aval, 2) / 4) / (Math.PI * Math.pow(elarg_amont, 2) / 4));
                        intermediaire = 0.5 * Math.pow(1 - Math.pow(intermediaire, 2), 2);
                        intermediaire1 = debit / (Math.PI * Math.pow(elarg_aval, 2) / 4) / Math.pow(10, -6) / 3600;
                        intermediaire = intermediaire * 0.5 * masse * Math.pow(intermediaire1, 2);

                        reduc_brusque = Math.round(intermediaire * 10) / 10;
                    }

                    reduc_conique = null;
                    intermediaire = null;
                    intermediaire1 = null;

                    if (parseFloat(elarg_amont) > parseFloat(elarg_aval)) {
                        intermediaire = -0.25 * Math.pow(Math.pow(elarg_aval / elarg_amont, 2), 2) + 0.15 * Math.pow(elarg_aval / elarg_amont, 2) + 0.06;
                        intermediaire1 = debit / (Math.PI * Math.pow(elarg_aval, 2) / 4) / Math.pow(10, -6) / 3600;
                        intermediaire = intermediaire * 0.5 * masse * Math.pow(intermediaire1, 2);

                        reduc_conique = Math.round(intermediaire * 10) / 10;
                    }

                    t_recti = null;
                    intermediaire_recti = null;
                    t_lat = null;
                    intermediaire_lat = null;

                    if ((rectiligne_aval != null) && (rectiligne_lateral != null)) {
                        if (parseFloat(rectiligne_lateral) < parseFloat(rectiligne_aval)) {
                            intermediaire_recti = (-1) * (Math.pow((rectiligne_lateral / debit), 2)) + 1.548485 * (rectiligne_lateral / debit) + 0.003333;
                            t_recti = 0.5 * intermediaire_recti * masse * Math.pow(vitesse2, 2);
                            t_recti = Math.round(t_recti * 10) / 10;

                            intermediaire_lat = (0.032346) * (Math.pow((rectiligne_lateral / debit), 2)) + 0.54728 * Math.pow((rectiligne_lateral / debit), 2) + 0.437259 * (rectiligne_lateral / debit) + 0.268461;
                            t_lat = 0.5 * intermediaire_lat * masse * Math.pow(vitesse2, 2);
                            t_lat = Math.round(t_lat * 10) / 10;
                        }
                    }
                }

                context.commit('SET_RESULT', {
                    debit_round,
                    vitesse_round,
                    perte_charge_lineaire,
                    coude30,
                    coude45,
                    coude90,
                    lateral90,
                    souche,
                    sortie,
                    elarg_brusque,
                    elarg_conique,
                    reduc_brusque,
                    reduc_conique,
                    t_lat,
                    t_recti
                });
                context.commit('SET_STEP', 1);
            },
            async send(context, {email, accept}) {
                console.log('send', email, accept);
                const assoc = [
                    {
                        name: 'Type',
                        value: context.state.type === PA_TYPES.RECTANGULAR ? i18n.t('paRectangular') : i18n.t('paCircular'),
                        unit: null
                    },
                    {
                        name: i18n.t('paValueToCompute'),
                        value: context.state.processType === PROCESS_TYPE.FLOW ? i18n.t('flowRate') : i18n.t('paSpeed'),
                        unit: null
                    },
                    {
                        name: i18n.t('paDensity'),
                        value: numberize(context.state.density, 1),
                        unit: 'kg/m3'
                    },
                    {
                        name: i18n.t('diameter'),
                        value: numberize(context.state.circular.diameter, 1),
                        unit: 'mm',
                        display: context.state.type === PA_TYPES.CIRCULAR
                    },
                    {
                        name: i18n.t('flowRate'),
                        value: numberize(context.getters.flow, 1),
                        unit: 'm3/h'
                    },
                    {
                        name: i18n.t('paSpeed'),
                        value: numberize(context.getters.speed, 1),
                        unit: 'm/s'
                    },
                    {
                        name: i18n.t('paLinearPressureLoss'),
                        value: numberize(context.getters.linearPressureLoss, 1),
                        unit: 'Pa/m'
                    },
                    {
                        name: i18n.t('paAccidentalPressureLoss'),
                        type: 'group',
                        children: [
                            {
                                name: i18n.t('paElbow30') + (context.state.type === PA_TYPES.CIRCULAR ? 'Ø' : ''),
                                value: numberize(context.getters.coude30, 1),
                                unit: 'Pa'
                            },
                            {
                                name: i18n.t('paElbow45') + (context.state.type === PA_TYPES.CIRCULAR ? 'Ø' : ''),
                                value: numberize(context.getters.coude45, 1),
                                unit: 'Pa'
                            },
                            {
                                name: i18n.t('paElbow90') + (context.state.type === PA_TYPES.CIRCULAR ? 'Ø' : ''),
                                value: numberize(context.getters.coude90, 1),
                                unit: 'Pa'
                            },
                            {
                                name: i18n.t('paLateralTee90'),
                                value: numberize(context.getters.lateral90, 1),
                                unit: 'Pa'
                            },
                            {
                                name: i18n.t('paRootTee90'),
                                value: numberize(context.getters.souche, 1),
                                unit: 'Pa'
                            },
                            {
                                name: i18n.t('paNetworkOutput'),
                                value: numberize(context.getters.output, 1),
                                unit: 'Pa'
                            },
                            {type: 'spacer'},
                            {
                                name: 'Ø '+ i18n.t('upstream'),
                                value: numberize(context.state.circular.diameter, 1),
                                unit: 'mm',
                                display: context.state.type === PA_TYPES.CIRCULAR
                            },
                            {
                                name: 'Ø '+ i18n.t('downstream'),
                                value: numberize(context.state.circular.downstreamDiameter, 1),
                                unit: 'mm',
                                display: context.state.type === PA_TYPES.CIRCULAR
                            },
                            {
                                name: i18n.t('paDownstreamHeight'),
                                value: numberize(context.state.rectangular.downstreamHeight, 1),
                                display: context.state.type === PA_TYPES.RECTANGULAR
                            },
                            {
                                name: i18n.t('paDownstreamWidth'),
                                value: numberize(context.state.rectangular.downstreamWidth, 1),
                                display: context.state.type === PA_TYPES.RECTANGULAR
                            },
                            {
                                name: i18n.t('paAbruptExpansion'),
                                value: numberize(context.getters.abruptExpansion, 1),
                                unit: 'Pa'
                            },
                            {
                                name: i18n.t('paConicalExpansion'),
                                value: numberize(context.getters.conicExpansion, 1),
                                unit: 'Pa'
                            },
                            {
                                name: i18n.t('paAbruptReduction'),
                                value: numberize(context.getters.abruptReduction, 1),
                                unit: 'Pa'
                            },
                            {
                                name: i18n.t('paConicalReduction'),
                                value: numberize(context.getters.conicReduction, 1),
                                unit: 'Pa'
                            },
                            {type: 'spacer'},
                            {
                                name: i18n.t('paDownstreamFlowRate'),
                                value: numberize(context.state.downstreamFlow, 1),
                                unit: 'Pa'
                            },
                            {
                                name: i18n.t('lateralFlowStream'),
                                value: numberize(context.state.lateralFlow, 1),
                                unit: 'Pa'
                            },
                            {
                                name: i18n.t('paStraightTee90'),
                                value: numberize(context.getters.straightTee90, 1),
                                unit: 'Pa'
                            },
                            {
                                name: i18n.t('paLateralTee90'),
                                value: numberize(context.getters.lateralTee90, 1),
                                unit: 'Pa'
                            }
                        ]
                    }
                ];

                const emailParts = assoc.reduce((prev, current) => {
                    if (current.display !== undefined && !current.display) return prev;
                    if (current.type === 'spacer') {
                        prev.children.push(null);
                        return prev;
                    }
                    if (current.children) {
                        prev.children.push({
                            label: current.name
                        })
                        prev.children = current.children.reduce((p, c) => {
                            if (c.type === 'spacer') {
                                p.push(null);
                                return p;
                            }
                            p.push({
                                value: c.value + (c.unit ? ' ' + c.unit : ''),
                                label: ' | ' + c.name
                            })
                            return p;
                        }, prev.children);
                        return prev;
                    }
                    prev.children.push({
                        value: current.value + (current.unit ? ' ' + current.unit : ''),
                        label: current.name
                    })
                    return prev;
                }, {
                    title: i18n.t('data'),
                    children: []
                });

                function _title(txt) {
                    return {
                        text: txt,
                        importance: 3,
                        margin: [0, 0, 2, 0]
                    }
                }

                function _filed(name, value) {
                    return {name, value};
                }

                const pdfData = [
                    {
                        type: "group",
                        title: i18n.t('paCalculatorResults'),
                        marginIncrement: 10,
                        child: [
                            {
                                type: "square",
                                title: i18n.t('results'),
                                data: [
                                    {
                                        type: "list",
                                        title: _title( i18n.t('mainInformations')),
                                        list: []
                                    }
                                ]
                            },
                            {
                                type: "square",
                                title: i18n.t('paAccidentalPressureLoss'),
                                data: [
                                    {
                                        type: "list",
                                        title: false,
                                        list: []
                                    },
                                    {
                                        type: "list",
                                        title: false,
                                        list: []
                                    },
                                    {
                                        type: "list",
                                        title: false,
                                        list: []
                                    }
                                ]
                            }
                        ]
                    }
                ];
                let GLB_INDEX = 0;
                let SQR_INDEX = 0;
                let LST_INDEX = 0;

                const assocs = assoc.reduce((prev, current) => {
                    if (current.type === 'group') {
                        prev.push(current);
                        prev.push(...current.children);
                        return prev;
                    }
                    prev.push(current);
                    return prev;
                }, []);

                for (const as of assocs) {
                    if (as.display !== undefined && !as.display) continue;
                    if (as.type === 'group') {
                        SQR_INDEX += 1;
                        continue;
                    }
                    if (as.type === 'spacer') {
                        LST_INDEX += 1;
                        continue;
                    }
                    console.log({SQR_INDEX, LST_INDEX}, as);
                    const value = as.unit ? as.value + ' ' + as.unit : as.value;
                    pdfData[GLB_INDEX].child[SQR_INDEX].data[LST_INDEX].list.push(_filed(as.name, value));
                }


                const json = {
                    type: "pa",
                    provider: "web",
                    title: i18n.t('paCalculatorResults'),
                    unit: '',
                    value: '',
                    parts: [emailParts],
                    footer: i18n.t('emailFooter'),
                    send: {
                        email: email,
                        accept: accept
                    },
                    pdf: pdfData
                }
                context.commit('SET_REQUESTING', true);
                try {
                    const response = await axios.post(CONFIG.api + '/pdf/mail/calculateur', json);
                    context.commit('SET_REQUESTING', false);
                    if (!response || response.code >= 300) {
                        context.commit('SET_ERROR', true);
                    }
                    context.commit('SET_STEP', 2);
                    return response;
                } catch (e) {
                    context.commit('SET_REQUESTING', false);
                }

            }
        }
    }
}
