<template>
  <div class="modal modal-info" :class="{open: modalOpenState}">
    <div class="modal-backdrop" @click="close"></div>
    <div class="modal-wrapper">
      <div class="modal-header">
        <span>{{  $t('msModalTitle') }}</span>
      </div>
      <div class="modal-content">
        <div class="grid-modal-content">
          <div></div>
          <div class="bg">{{ $t('msModalMainDuct') }}</div>
          <div class="bg">{{ $t('msModalBypass') }}</div>
          <div class="bg">{{ $t('msModalHabitat') }}</div>
          <div>6 m/s</div>
          <div>5 m/s</div>
          <div class="bg">{{ $t('msModalTertiary') }}</div>
          <div>6 m/s</div>
          <div>4 m/s</div>
          <div class="bg">{{ $t('msModalWorkshop') }}</div>
          <div>8 m/s</div>
          <div>6 m/s</div>
        </div>
      </div>
      <div class="modal-bottom">
        <button class="app-btn" @click="close">{{ $t('close') }}</button>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: "modal-ms",
  computed: {
    ...mapState({
      modalOpenState: s => s.ms.modalState
    })
  },
  methods: {
    close () {
      this.$store.commit('ms/SET_MODAL_STATE', false);
    }
  }
}
</script>

<style scoped lang="scss">
.modal {
  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.2);
    transition: all 300ms ease;
    opacity: 1;
  }
  .modal-bottom {
    margin-top: 1.5rem;
    text-align: center;
    button {
      border-radius: 3px;
      border: 2px solid #0077b3;
      padding: .45rem 1.35rem;
      background: #0077b3;
      color: white;
      cursor: pointer;
      &:hover {
        background: white;
        border-color: #0077b3;
        color: #0077b3;
      }
    }
  }
  .modal-wrapper {
    position: fixed;
    top: 10%;
    left: calc(50% - (590px/2));
    width: 560px;
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 14px 4px rgba(0,0,0,0.2);
    transition: all 500ms ease;
    opacity: 1;
    transform: translateY(0);

    @media (max-width: 768px) {
      left: 0;
      bottom: 0;
      right: 0;
      top: auto;
      width: auto;
    }

    .modal-header {
      margin-bottom: 1.5rem;
      font-size: 1.1em;
    }
    .modal-content {
      .grid-modal-content {
        display: grid;
        grid-template-areas: 'a b c' 'd e f' 'g h i';
        align-items: center;
        grid-template-columns: repeat(3, calc(100%/3));
        div:nth-child(1) {grid-area: a;}
        div:nth-child(2) {grid-area: b;}
        div:nth-child(3) {grid-area: c;}
        div:nth-child(4) {grid-area: d;}
        div:nth-child(5) {grid-area: e;}
        div:nth-child(6) {grid-area: f;}
        div:nth-child(7) {grid-area: g;}
        div:nth-child(8) {grid-area: h;}
        div:nth-child(9) {grid-area: i;}
        div {
          padding: .35rem .15rem;
          text-align: center;
        }
        .bg {
          background: #0077b3;
          color: white;
        }
      }
    }
  }
}
.modal:not(.open) {
  .modal-backdrop {
    display: none;
  }
  .modal-wrapper {
    display: none;
  }
}

</style>
