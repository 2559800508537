import numberize from "../_helper/numberize.helper";
import axios from "axios";
import {CONFIG} from "@/config/config";

export default function (i18n) {
    return {
        namespaced: true,
        state: {
            flowRate: 0, // débit
            maxSpeedExpected: 4,
            input: {
                diameters: [125, 160, 200, 250, 315, 355, 400, 450, 500, 560, 630, 710, 800, 900, 1000, 1120, 1250]
            },
            step: 0,
            requesting: false,
            hasError: false,
            modalState: false,
        },
        getters: {
            result: state => {
                return state.input.diameters.map(diameter => {
                    return {
                        diameter: diameter,
                        value: state.flowRate / 3600 / (Math.PI * (diameter / 1000) * (diameter / 1000) / 4)
                    }
                })
            },
            diameter: (state, getters) => {
                return getters['result'].slice().reverse().reduce((prev, current) => {
                    if (!prev) return current;
                    if (current.value > state.maxSpeedExpected) return prev;
                    return current;
                }, null);
            }
        },
        mutations: {
            SET_MODAL_STATE (state, payload) {
                state.modalState = !!payload;
            },
            SET_STEP(state, payload) {
                state.step = payload ? parseInt(payload) : 0;
            },
            SET_REQUESTING(state, payload) {
                state.requesting = !!payload;
                state.hasError = false;
            },
            SET_ERROR(state, payload) {
                state.hasError = !!payload;
            },
            SET_MAX_SPEED(state, payload) {
                state.maxSpeedExpected = payload;
            },
            SET_FLOW_RATE(state, payload) {
                state.flowRate = payload;
            }
        },
        actions: {
            async send(context, {email, accept}) {

                const dataChildren = {};
                for (const child of context.getters.result) {
                    let prefix = '';
                    if (child.diameter === context.getters.diameter.diameter) prefix = '**';
                    dataChildren[prefix + child.diameter + ' mm'] = numberize(child.value, 1) + ' m/s';
                }

                const children = {
                    "flowRate": `${context.state.flowRate} m3/h`,
                    "msMaxSpeedExpected": `${context.state.maxSpeedExpected} m/s`
                };

                const translatedChildren = {};
                Object.keys(children).forEach(key => {
                    translatedChildren[i18n.t(key)] = children[key];
                });

                const json = {
                    type: "ms",
                    provider: "web",
                    title: i18n.t("msCalculatorResults"),
                    unit: '',
                    value: '',
                    parts: [
                        {
                            title: i18n.t("data"),
                            children: translatedChildren
                        },
                        {
                            title: i18n.t("results"),
                            children: dataChildren
                        }
                    ],
                    footer: "" +
                        i18n.t('emailFooter'),
                    send: {
                        email: email,
                        accept: accept
                    },
                    pdf: [
                        {
                            type: "group",
                            title: i18n.t("msCalculatorResults"),
                            marginIncrement: 10,
                            child: [
                                {
                                    type: "square",
                                    title: i18n.t("data"),
                                    data: [
                                        {
                                            type: "text",
                                            name: i18n.t('flowRate'),
                                            value: numberize(context.state.flowRate, 1) + " m3/s"
                                        },
                                        {
                                            type: "text",
                                            name: i18n.t("msMaxSpeedExpected"),
                                            value: numberize(context.state.maxSpeedExpected, 1) + " m/s"
                                        }
                                    ]
                                },
                                {
                                    type: "square",
                                    title: i18n.t("results"),
                                    data: [
                                        {
                                            type: "grid",
                                            template: [50, 50],
                                            child: [
                                                {
                                                    type: "text",
                                                    name: i18n.t('diameter')+" (mm)",
                                                    align: "C",
                                                    bold: true
                                                },
                                                {
                                                    type: "text",
                                                    name: i18n.t('paSpeed')+" (m/s)",
                                                    align: "C",
                                                    bold: true
                                                },
                                                ...(context.getters.result.map((item) => {
                                                    if (item.diameter === context.getters.diameter.diameter) {
                                                        return [
                                                            {
                                                                type: "text",
                                                                name: numberize(item.diameter, 0),
                                                                fontSize: 3,
                                                                bold: true,
                                                                align: "C"
                                                            },
                                                            {
                                                                type: "text",
                                                                name: numberize(item.value, 1),
                                                                fontSize: 3,
                                                                bold: true,
                                                                align: "C"
                                                            },
                                                        ]
                                                    }
                                                    return [
                                                        {
                                                            type: "text",
                                                            name: numberize(item.diameter, 0),
                                                            align: "C"
                                                        },
                                                        {
                                                            type: "text",
                                                            name: numberize(item.value, 1),
                                                            align: "C"
                                                        },
                                                    ]
                                                }).reduce((prev, current) => {
                                                    prev.push(...current);
                                                    return prev;
                                                }, []))
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
                context.commit('SET_REQUESTING', true);
                const response = await axios.post(CONFIG.api + '/pdf/mail/calculateur', json);
                context.commit('SET_REQUESTING', false);
                if (!response || response.code >= 300) {
                    context.commit('SET_ERROR', true);
                }
                context.commit('SET_STEP', 2);
                return response;
            }
        }
    }
}
